import React, { useEffect, useState } from "react";
import axios, { baseUrl } from "../../utils/axios";
import { message, Row, Col } from "antd";
import { useParams } from "react-router-dom";
import { PageContainer } from "../../components/Common";
import { StyledDivider } from "../../components/Divider";
import Information from "./Information";
import Users from "./Users";
import Settings from "./Settings";
import PlanDetails from "./PlanDetails";
import { OrgUUIDContext } from "../../context";
import Loading from "../../components/Loading";
import moment from "moment";

const OrganizationDetail = () => {
  const params = useParams();
  const orgUUID = params.id;
  const [allPlans, setAllPlans] = useState(null);

  const [loading, setLoading] = useState(true);
  const [fetchPlansLoader, setFetchPlansLoader] = useState(true);

  // organization information
  const [data, setData] = useState(null);

  // Plan Details
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [priceValue, setPriceValue] = useState(null);
  const [jobPostings, setJobPostings] = useState(null);
  const [recruiters, setRecruiters] = useState(null);

  // Trial Data
  const [trialEndDate, setTrialEndDate] = useState(null);

  useEffect(() => {
    const fetchOrganizations = async () => {
      setLoading(true);
      try {
        const res = await axios.get(
          `${baseUrl}/api/organization/details/${orgUUID}`
        );
        console.log("data", res.data);
        setData(res?.data[0]);

        // Plan Details
        if (res?.data?.plan_id === 1) {
          setSelectedPlan(2);
        } else {
          setSelectedPlan(res?.data?.plan_id > 3 ? 4 : res?.data?.plan_id);
          setPriceValue(res?.data?.planDetails?.price);
          setJobPostings(res?.data?.planDetails?.totalJobs);
          setRecruiters(res?.data?.planDetails?.totalRecruiters);
        }

        // Trial
        setTrialEndDate(moment(res?.data?.trial_end_date));
      } catch (err) {
        message.error(err?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    };
    if (orgUUID) fetchOrganizations();
  }, [orgUUID]);

  useEffect(() => {
    const fetchPlans = async () => {
      const res = await axios.get(`${baseUrl}/api/plan`);
      setAllPlans(res?.data);
      setFetchPlansLoader(false);
    };
    fetchPlans();
  }, []);

  return (
    <OrgUUIDContext.Provider value={orgUUID}>
      <PageContainer>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Row>
              <Col xs={24} md={16} lg={10}>
                <Information
                  data={{
                    name: data?.name,
                    code: data?.code,
                    address: data?.address,
                    naukriGulfPostingLimit: data?.naukri_gulf_posting_limit,
                    status: data?.status,
                  }}
                />
              </Col>
              <Col xs={24} md={16} lg={{ span: 10, offset: 4 }}>
                {fetchPlansLoader ? (
                  <Loading />
                ) : (
                  <PlanDetails
                    organizationDetails={data}
                    allPlans={allPlans}
                    data={{ selectedPlan, priceValue, jobPostings, recruiters }}
                    handlers={{
                      setSelectedPlan,
                      setPriceValue,
                      setJobPostings,
                      setRecruiters,
                      setData,
                    }}
                  />
                )}
              </Col>
            </Row>
            <StyledDivider />
            {data?.status === "TRIAL" && (
              <>
                <Row>
                  <Col md={16} lg={10}>
                    <Settings
                      trialEndDate={trialEndDate}
                      setTrialEndDate={setTrialEndDate}
                    />
                  </Col>
                </Row>
                <StyledDivider />
              </>
            )}
            <Row>
              <Col xs={24}>
                <Users />
              </Col>
            </Row>
            <br />
            <br />
          </>
        )}
      </PageContainer>
    </OrgUUIDContext.Provider>
  );
};

export default OrganizationDetail;
